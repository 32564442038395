<template>
    <div class="SummaryReport clearfix">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm" :inline="true">
            <el-col :span="11">
                <el-form-item prop="purpose" label="一、项目的意义及前景" style="width:100%">
                    <el-input type="textarea" :rows="8" placeholder="请填写项目实施的目的和意义" v-model="ruleForm.purpose"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="2">
                <div style="height:10px">

                </div>
            </el-col>
            <el-col :span="11">
                <el-form-item prop="keyproblem" label="二、项目研究内容和拟解决的关键问题" style="width:100%">
                    <el-input type="textarea" :rows="8" placeholder="请填写项目研究内容和拟解决的关键问题" v-model="ruleForm.keyproblem"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="11">
                <el-form-item prop="Basicitions" label="三、项目的进度安排" style="width:100%">
                    <el-input type="textarea" :rows="8" placeholder="请填写项目研究和实施的基础条件" v-model="ruleForm.Basicitions"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="2">
                <div style="height:10px">

                </div>
            </el-col>
            <el-col :span="11">
                <el-form-item prop="Impleplan" label="四、项目总结" style="width:100%">
                    <el-input type="textarea" :rows="8" placeholder="请填写项目实施方案" v-model="ruleForm.Impleplan"></el-input>
                </el-form-item>
            </el-col>
        </el-form>
        <div class="next_step fl">
            <el-button type="primary" @click="previous('ruleForm')">上一步</el-button>
            <el-button type="primary" @click="newtion('ruleForm')">下一步</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ruleForm:{},
            rules:{},
        }
    },
    methods: {
        previous(){
            this.$router.push('/BasicProject')
        },
        newtion(){
            this.$router.push('/Useoffunds')
        },
    },
    mounted() {
        
    },
}
</script>
<style scoped>
@import './../../assets/css/student/Postprojectreport.css';
</style>
<style>
.SummaryReport .el-form-item__content{
    width: 100%;
}
.SummaryReport .el-form-item__label{
    width: 100%!important;
    text-align: left!important;
}
.SummaryReport .el-textarea__inner{
    resize: none;
}
.SummaryReport .el-form-item__label{
    font-size: 15px;
    font-weight: bold;
}
</style>